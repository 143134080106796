function Card({ className, children }) {
  return (
    <div className={`bg-white rounded-lg shadow ${className}`}>
      {children}
    </div>
  );
}

function CardHeader({ children }) {
  return <div className="p-6 border-b">{children}</div>;
}

function CardTitle({ children, className }) {
  return <h2 className={`text-xl font-bold ${className}`}>{children}</h2>;
}

function CardContent({ children }) {
  return <div className="p-6">{children}</div>;
}

function CardDescription({ children }) {
  return <p className="text-gray-600 mt-2">{children}</p>;
}

export { Card, CardHeader, CardTitle, CardContent, CardDescription };