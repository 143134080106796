import * as TabsPrimitive from '@radix-ui/react-tabs';

const Tabs = TabsPrimitive.Root;
const TabsList = ({ className, ...props }) => (
  <TabsPrimitive.List className={`flex border-b ${className}`} {...props} />
);
const TabsTrigger = ({ className, ...props }) => (
  <TabsPrimitive.Trigger
    className={`px-4 py-2 -mb-px text-sm font-medium border-b-2 border-transparent hover:border-gray-300 focus:outline-none ${className}`}
    {...props}
  />
);
const TabsContent = TabsPrimitive.Content;

export { Tabs, TabsList, TabsTrigger, TabsContent };