import { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, PieChart, Pie, Cell } from 'recharts';
import { DollarSign, Users, Clock, Calculator, BarChart2 } from 'lucide-react';

function StaffingValueCalculator() {
  const [baseSalary, setBaseSalary] = useState(29500);
  const [numEmployees, setNumEmployees] = useState(5);
  const [turnoverRate, setTurnoverRate] = useState(20);
  const [directRecruitingHours, setDirectRecruitingHours] = useState(10);
  const [resumeReviewHours, setResumeReviewHours] = useState(8);
  const [referenceCheckHours, setReferenceCheckHours] = useState(4);
  const [hourlyRate, setHourlyRate] = useState(50);

  // Constants
  const hoursPerYear = 1950;
  const advertisingCosts = 500;
  const backgroundCheckCost = 150;
  const skillsTestingCost = 200;
  const benefitsCostFactor = 0.35;
  
  // Updated color schemes for better impact
  const BRAND_COLORS = ['#FFB74D', '#4DB6AC', '#7986CB', '#81C784', '#4DD0E1', '#9575CD', '#FF8A65'];
  const CHART_COLORS = {
    primary: '#4DB6AC',
    secondary: '#7986CB',
    tertiary: '#81C784'
  };

  const costFactors = {
    hourlyWage: 0.682,
    fica: 0.042,
    medicare: 0.01,
    suta: 0.006,
    workComp: 0.115,
    gAndA: 0.106,
    staffingProfit: 0.036
  };

  const calculateEmploymentCosts = () => {
    const hourlyRate = baseSalary / hoursPerYear;
    const totalCost = (baseSalary / costFactors.hourlyWage);
    
    return {
      base: baseSalary,
      total: totalCost,
      breakdown: {
        hourlyWage: totalCost * costFactors.hourlyWage,
        fica: totalCost * costFactors.fica,
        medicare: totalCost * costFactors.medicare,
        suta: totalCost * costFactors.suta,
        workComp: totalCost * costFactors.workComp,
        gAndA: totalCost * costFactors.gAndA,
        staffingProfit: totalCost * costFactors.staffingProfit
      }
    };
  };

  const calculateHiringCosts = () => {
    const baseHourlyRate = baseSalary / hoursPerYear;
    const benefitsCost = baseHourlyRate * benefitsCostFactor;
    const totalHourlyRate = baseHourlyRate + benefitsCost;
    const recruitingCosts = (directRecruitingHours + resumeReviewHours + referenceCheckHours) * hourlyRate;
    const conversionPeriodCost = totalHourlyRate * 520;
    const totalDirectCosts = conversionPeriodCost + recruitingCosts + advertisingCosts + backgroundCheckCost + skillsTestingCost;
    
    return {
      traditional: {
        recruiting: recruitingCosts,
        conversion: conversionPeriodCost,
        other: advertisingCosts + backgroundCheckCost + skillsTestingCost,
        total: totalDirectCosts
      },
      staffing: {
        recruiting: 0,
        conversion: 22.50 * 520,
        other: 0,
        total: 22.50 * 520
      }
    };
  };

  const employmentCosts = calculateEmploymentCosts();
  const hiringCosts = calculateHiringCosts();
  const annualTurnoverCost = (turnoverRate / 100) * numEmployees * hiringCosts.traditional.total;
  const annualSavingsWithStaffing = (hiringCosts.traditional.total - hiringCosts.staffing.total) * (numEmployees * (turnoverRate / 100));

  const pieData = [
    { name: 'Base Wages', value: employmentCosts.breakdown.hourlyWage },
    { name: 'FICA', value: employmentCosts.breakdown.fica },
    { name: 'Medicare', value: employmentCosts.breakdown.medicare },
    { name: 'SUTA', value: employmentCosts.breakdown.suta },
    { name: 'Workers Comp', value: employmentCosts.breakdown.workComp },
    { name: 'G&A', value: employmentCosts.breakdown.gAndA },
    { name: 'Staffing Profit', value: employmentCosts.breakdown.staffingProfit }
  ];

  const barData = [
    {
      name: 'Traditional Hiring',
      Recruiting: hiringCosts.traditional.recruiting,
      Conversion: hiringCosts.traditional.conversion,
      Other: hiringCosts.traditional.other
    },
    {
      name: 'Excel Personnel',
      Recruiting: hiringCosts.staffing.recruiting,
      Conversion: hiringCosts.staffing.conversion,
      Other: hiringCosts.staffing.other
    }
  ];

  return (
    <Card className="w-full max-w-6xl">
      <CardHeader>
        <CardTitle className="flex items-center gap-2 text-xl">
          <Calculator className="w-6 h-6" />
          Excel Personnel Value Calculator
        </CardTitle>
        <CardDescription className="mt-2">
          Compare the true costs of direct hiring vs using Excel Personnel
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="inputs" className="space-y-4">
          <TabsList>
            <TabsTrigger value="inputs">Inputs</TabsTrigger>
            <TabsTrigger value="costs">Cost Analysis</TabsTrigger>
            <TabsTrigger value="savings">Savings Summary</TabsTrigger>
          </TabsList>

          <TabsContent value="inputs">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="space-y-4">
                <h3 className="font-bold">Base Information</h3>
                <div>
                  <Label htmlFor="baseSalary">Annual Base Salary ($)</Label>
                  <div className="flex items-center">
                    <DollarSign className="w-4 h-4 mr-2" />
                    <Input
                      id="baseSalary"
                      type="number"
                      value={baseSalary}
                      onChange={(e) => setBaseSalary(Number(e.target.value))}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="numEmployees">Number of Positions</Label>
                  <div className="flex items-center">
                    <Users className="w-4 h-4 mr-2" />
                    <Input
                      id="numEmployees"
                      type="number"
                      value={numEmployees}
                      onChange={(e) => setNumEmployees(Number(e.target.value))}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="turnover">Annual Turnover Rate (%)</Label>
                  <div className="flex items-center">
                    <BarChart2 className="w-4 h-4 mr-2" />
                    <Input
                      id="turnover"
                      type="number"
                      value={turnoverRate}
                      onChange={(e) => setTurnoverRate(Number(e.target.value))}
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="font-bold">Hiring Process Hours</h3>
                <div>
                  <Label htmlFor="recruitingHours">Direct Recruiting</Label>
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 mr-2" />
                    <Input
                      id="recruitingHours"
                      type="number"
                      value={directRecruitingHours}
                      onChange={(e) => setDirectRecruitingHours(Number(e.target.value))}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="reviewHours">Resume Review</Label>
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 mr-2" />
                    <Input
                      id="reviewHours"
                      type="number"
                      value={resumeReviewHours}
                      onChange={(e) => setResumeReviewHours(Number(e.target.value))}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="referenceHours">Reference Checks</Label>
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 mr-2" />
                    <Input
                      id="referenceHours"
                      type="number"
                      value={referenceCheckHours}
                      onChange={(e) => setReferenceCheckHours(Number(e.target.value))}
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="font-bold">Fixed Costs Included</h3>
                <ul className="space-y-2">
                  <li>Advertising: ${advertisingCosts}</li>
                  <li>Background Check: ${backgroundCheckCost}</li>
                  <li>Skills Testing: ${skillsTestingCost}</li>
                  <li>Benefits Factor: {benefitsCostFactor * 100}%</li>
                  <li>Conversion Period: 520 hours</li>
                </ul>
              </div>
            </div>
          </TabsContent>

          <TabsContent value="costs">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="h-80">
                <h3 className="font-bold mb-2">Employment Cost Breakdown</h3>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={pieData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={2}
                      dataKey="value"
                    >
                      {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={BRAND_COLORS[index % BRAND_COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>

              <div className="h-80">
                <h3 className="font-bold mb-2">Hiring Cost Comparison</h3>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={barData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
                    <Legend />
                    <Bar dataKey="Recruiting" fill={CHART_COLORS.primary} stackId="a" />
                    <Bar dataKey="Conversion" fill={CHART_COLORS.secondary} stackId="a" />
                    <Bar dataKey="Other" fill={CHART_COLORS.tertiary} stackId="a" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </TabsContent>

          <TabsContent value="savings">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="p-6 bg-gray-50 rounded-lg">
                <h3 className="font-bold text-lg mb-4">Cost Impact Summary</h3>
                <div className="space-y-4">
                  <div>
                    <p className="font-semibold">True Cost Per Employee</p>
                    <p className="text-2xl">${employmentCosts.total.toLocaleString()}</p>
                  </div>
                  <div>
                    <p className="font-semibold">Traditional Hiring Cost (per position)</p>
                    <p className="text-2xl">${hiringCosts.traditional.total.toLocaleString()}</p>
                  </div>
                  <div>
                    <p className="font-semibold">Excel Personnel Cost (per position)</p>
                    <p className="text-2xl">${hiringCosts.staffing.total.toLocaleString()}</p>
                  </div>
                </div>
              </div>

              <div className="p-6 bg-blue-50 rounded-lg">
                <h3 className="font-bold text-lg mb-4">Annual Impact</h3>
                <div className="space-y-4">
                  <div>
                    <p className="font-semibold">Annual Turnover Cost (Traditional)</p>
                    <p className="text-2xl text-red-600">${annualTurnoverCost.toLocaleString()}</p>
                  </div>
                  <div>
                    <p className="font-semibold">Potential Annual Savings with Excel Personnel</p>
                    <p className="text-2xl text-green-600">${annualSavingsWithStaffing.toLocaleString()}</p>
                  </div>
                </div>
              </div>

              <div className="md:col-span-2 p-6 bg-gray-50 rounded-lg">
                <h3 className="font-bold text-lg mb-4">Excel Personnel Advantages</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <ul className="list-disc pl-5 space-y-2">
                    <li>No liability or risk during 520-hour evaluation period</li>
                    <li>All recruiting and screening costs included</li>
                    <li>Free replacement if candidate doesn't work out</li>
                  </ul>
                  <ul className="list-disc pl-5 space-y-2">
                    <li>Background checks and skills testing included</li>
                    <li>Simplified payroll and benefits administration</li>
                    <li>Reduced HR administrative burden</li>
                  </ul>
                </div>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
}

export default StaffingValueCalculator;