import StaffingValueCalculator from './components/StaffingValueCalculator';

function App() {
  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4">
      <div className="max-w-7xl mx-auto">
        <StaffingValueCalculator />
      </div>
    </div>
  );
}

export default App;